import { Link } from "react-scroll";
import { rightArrow } from "../../helpers/svg-helper";
import { SectionContainer } from "./SectionContainer";
import { Heading1, Heading2 } from "../typography/Headings";
import { GridContainer } from "../grid/GridContainer";

const Hero = () => {
  return (
    <SectionContainer id="hero">
      <GridContainer className="grow grid grid-col-1 gap-12 pt-20 md:grid-cols-2">
        {/* First Grid item */}
        <div>
          <Heading1>Hi, I'm</Heading1>
          <Heading2 className="max-w-fit mt-1 text-3xl text-slate-200 font-bold leading-relaxed tracking-wide whitespace-nowrap md:text-5xl">
            Dieter Lötz.
          </Heading2>
          <div className="flex flex-col mt-6 text-slate-300 text-sm font-medium md:text-base md:mt-14">
            <span className="leading-8">
              I'm a dedicated software engineer based in Cape Town, South
              Africa. I specialize in crafting high-quality software solutions,
              with a particular focus on developing robust websites and backend
              applications. <br />
              <br />
              Committed to continuous improvement, I prioritize ongoing learning
              to enhance my skill set and stay at the forefront of technology.
            </span>
          </div>
          <div className="hidden mt-20 place-self-center md:place-self-start md:block">
            <Link
              spy={true}
              smooth={true}
              duration={250}
              offset={-45}
              to="about"
              className="border border-lightSecondaryAccent rounded-md px-5 py-3 text-sm text-slate-300 font-medium
                focus:transition-all focus:bg-lightSecondaryAccent focus:bg-opacity-80 focus:font-bold focus:text-veryDarkPrimaryAccent
                hover:animate-fade150 hover:bg-lightSecondaryAccent hover:bg-opacity-80 hover:font-bold hover:text-veryDarkPrimaryAccent hover:cursor-pointer"
            >
              Learn more about me
              {rightArrow}
            </Link>
          </div>
        </div>
        {/* Second Grid item */}
        <div className="flex items-center justify-center w-full h-full">
          <div className="w-60 h-60 rounded-full bg-slate-300 bg-hero-pattern bg-bottom bg-contain bg-no-repeat bg-origin-border md:w-72 md:h-72"></div>
        </div>
      </GridContainer>
    </SectionContainer>
  );
};

export default Hero;
