import React, { ReactNode, useEffect, useRef } from 'react'

type SectionContainerProps = {
  id?: string
  children?: ReactNode
  className?: string
}

export const SectionContainer: React.FC<SectionContainerProps> = (props) => {
  const sectionRef = useRef<HTMLElement>(null)

  useEffect(() => {
    sectionRef?.current?.classList.remove('animate-fadeFromLeft300')
    void sectionRef?.current?.offsetWidth
    sectionRef?.current?.classList.add('animate-fadeFromLeft300')
  }, [sectionRef])

  return (
    <section
      id={props.id}
      ref={sectionRef}
      className={
        props.className ||
        'bg-veryDarkPrimaryAccent min-w-full min-h-screen px-5 py-10 transition md:px-36 md:flex md:items-center'
      }
    >
      {props.children}
    </section>
  )
}