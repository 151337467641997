import { SectionContainer } from "./SectionContainer";
import svgToDisplay from "../../assets/illustration-code.svg";
import Accordion from "../Accordion";
import { Heading1, Heading2 } from "../typography/Headings";
import { GridContainer } from "../grid/GridContainer";

const Experience = () => {
  const wongaExperience: string[] = [
    "Follow Agile Scrum and Kanban methodologies",
    "Develop, test and deploy features and/or bug fixes with varying degrees of complexity",
    "Integrate with third-party service providers",
    "Rewrite exisiting microservices onto newer cloud service templates which are hosted in AWS",
    "Review merge requests & update documentation",
    "Collaborate with various stakeholders and multidisciplinary teams",
    "Production support and resolution of incidents",
  ];

  return (
    <SectionContainer
      id="experience">
      <GridContainer>
        <div>
          <Heading1 className="text-sm text-lightSecondaryAccent md:text-base">
            Experience
          </Heading1>
          <Heading2 className="mt-1 text-slate-200 text-3xl font-bold leading-relaxed tracking-wide md:text-4xl">
            The Technical 🚀
          </Heading2>
          <section className="flex flex-col justify-bewteen mt-6 text-slate-300 text-sm font-medium md:text-base md:mt-6">
            <Accordion
              headerLabel="Wonga Online (Dec 2019 - Present)"
              items={wongaExperience}
            />
            <span className="mt-6 text-slate-200 leading-8">
              Here's a brief summary of my experience with various languages,
              frameworks and technologies.
            </span>
            <span className="leading-8">
              <span className="mt-4 mb-3 pl-1 text-lightSecondaryAccent">
                Frontend
              </span>
              <ul className="list-disc pl-5 md:whitespace-nowrap md:text-sm">
                <li className="my-3">React, Angular & Angular.js </li>
                <li className="my-3">TypeScript </li>
                <li className="my-3">
                  HTML5, CSS/SCSS, Styled Components, Material UI, Tailwind CSS
                </li>
              </ul>
              <span className="my-3 pl-1 text-lightSecondaryAccent">
                Backend
              </span>
              <ul className="list-disc pl-5 md:whitespace-nowrap md:text-sm">
                <li className="my-3">
                  C#, .NET Core & Framework, MVC, ASP.NET Web API's
                </li>
                <li className="my-3">Microsoft SQL Server, Postgres SQL </li>
                <li className="my-3">
                  Message Queuing via RabbitMQ, MSMQ & AWS SQS
                </li>
              </ul>
              <span className="my-3 pl-1 text-lightSecondaryAccent">
                Miscellaneous
              </span>
              <ul className="list-disc pl-5 md:whitespace-nowrap md:text-sm">
                <li className="my-3">Amazon Web Services (AWS)</li>
                <li className="my-3">Microservice architectures </li>
                <li className="my-3">Docker </li>
                <li className="my-3">CI/CD pipelines </li>
              </ul>
            </span>
          </section>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={svgToDisplay}
            alt="code illustration"
            className="w-80 h-80 md:w-3/4 md:h-3/4"
          />
        </div>
      </GridContainer>
    </SectionContainer>
  );
};

export default Experience;
