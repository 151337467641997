import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./components/sections/About";
import Contact from "./components/sections/Contact";
import Experience from "./components/sections/Experience";
import Hero from "./components/sections/Hero";
function App() {
  return (
    <main className="flex flex-col min-w-full min-h-full">
      <Navbar />
      <Hero />
      <About />
      <Experience />
      <Contact />
      <Footer />
    </main>
  );
}

export default App;
