import { useState } from "react";
import { Link } from "react-scroll";
import {
  chatBubble,
  codeBracketSquare,
  homeIcon,
  identificationIcon,
  menuCloseIcon,
  menuOpenIcon,
} from "../helpers/svg-helper";
import logo from "../assets/logo3.png";

const Navbar = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onMenuIconClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((open) => !open);
    e.currentTarget.classList.remove("animate-fade250");
    void e.currentTarget.offsetWidth;
    e.currentTarget.classList.add("animate-fade250");
  };

  const menuIcon = open ? menuOpenIcon : menuCloseIcon;

  return (
    <>
      <header className="fixed w-full mb-20 z-10">
        <div className="flex justify-between items-center px-10 bg-darkPrimaryAccent font-medium md:backdrop-blur-sm md:bg-opacity-80 w-full h-20 shadow-lg md:px-36">
          <Link
            className="flex items-center justify-center w-28 h-20 text-slate-300 text-base line hover:cursor-pointer"
            to="hero"
            onClick={() => setOpen(false)}
          >
            <img src={logo} alt="logo" className="w-20 h-20" />
          </Link>
          <nav className="hidden md:flex md:justify-end md:items-center md:gap-7 md:w-full">
            <Link
              to="hero"
              spy={true}
              smooth={true}
              duration={250}
              offset={-90}
              activeClass="text-white underline decoration-lightSecondaryAccent underline-offset-4 hover:underline"
              className="text-base text-slate-300 hover:cursor-pointer hover:text-white hover:underline hover:decoration-lightSecondaryAccent hover:underline-offset-4"
            >
              Home
            </Link>
            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={250}
              offset={-75}
              activeClass="text-white underline decoration-lightSecondaryAccent underline-offset-4 hover:underline"
              className="text-base text-slate-300 hover:cursor-pointer hover:text-white hover:underline hover:decoration-lightSecondaryAccent hover:underline-offset-4"
            >
              About me
            </Link>
            <Link
              spy={true}
              smooth={true}
              duration={250}
              offset={-70}
              activeClass="text-white underline decoration-lightSecondaryAccent underline-offset-4 hover:underline"
              className="text-base text-slate-300
              hover:cursor-pointer hover:text-white hover:underline hover:decoration-lightSecondaryAccent hover:underline-offset-4"
              to="experience"
            >
              Experience
            </Link>
            <Link
              spy={true}
              smooth={true}
              offset={-70}
              duration={250}
              activeClass="text-white underline decoration-lightSecondaryAccent underline-offset-4 hover:underline"
              className="text-base text-slate-300
              hover:cursor-pointer hover:text-white hover:underline hover:decoration-lightSecondaryAccent hover:underline-offset-4"
              to="contact"
            >
              Contact
            </Link>
          </nav>

          {/* Mobile menu */}
          <div className="realtive flex justify-end items-center w-full md:hidden">
            {open && (
              <nav className="absolute -bottom-48 right-3 w-1/2 h-auto rounded-lg shadow-lg bg-darkPrimaryAccent p-6 animate-fade250">
                <div className="flex flex-col gap-3 w-full pl-3">
                  <Link
                    spy={true}
                    smooth={true}
                    duration={150}
                    offset={-90}
                    className="flex gap-3 items-center justify-jusify text-base text-white hover:underline focus:underline underline-offset-4"
                    to="hero"
                    onClick={() => setOpen(false)}
                  >
                    {homeIcon}
                    Home
                  </Link>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={150}
                    offset={-80}
                    className="flex gap-3 items-center justify-jusify text-base text-white hover:underline focus:underline underline-offset-4"
                    to="about"
                    onClick={() => setOpen(false)}
                  >
                    {identificationIcon}
                    About me
                  </Link>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={150}
                    offset={-80}
                    className="flex gap-3 items-center justify-jusify text-base text-white hover:underline focus:underline underline-offset-4"
                    to="experience"
                    onClick={() => setOpen(false)}
                  >
                    {codeBracketSquare}
                    Experience
                  </Link>
                  <Link
                    spy={true}
                    smooth={true}
                    duration={150}
                    offset={-80}
                    className="flex gap-3 items-center justify-jusify text-base text-white hover:underline focus:underline underline-offset-4"
                    to="contact"
                    onClick={() => setOpen(false)}
                  >
                    {chatBubble}
                    Contact
                  </Link>
                </div>
              </nav>
            )}
            <button
              type="button"
              onClick={onMenuIconClickHandler}
              className="hover:cursor-pointer"
            >
              {menuIcon}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
