import React from "react";

type HeadingProps = {
    children: React.ReactNode;
    className?: string;
}

export const Heading1: React.FC<HeadingProps> = (props) => {
    return <h1 className={props.className ? props.className : "text-sm text-lightSecondaryAccent md:text-base"}>{props.children}</h1>
}

export const Heading2: React.FC<HeadingProps> = (props) => {
    return <h2 className={props.className ? props.className : "mt-1 text-slate-200 text-3xl font-bold leading-relaxed tracking-wide md:text-4xl"}>{props.children}</h2>
}