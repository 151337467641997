import { useState } from "react";
import { chevronRight } from "../helpers/svg-helper";

type AccordionProps = {
  headerLabel: string;
  items: string[];
};

const Accordion: React.FC<AccordionProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>();
  return (
    <>
      <h3 className="w-full">
        <button
          type="button"
          id="accordion1"
          aria-expanded="true"
          aria-controls="sect1"
          onClick={() => setIsExpanded((prev) => !prev)}
          className="w-full flex justify-between items-center px-4 h-10 bg-transparent rounded-md border border-lightSecondaryAccent transition-all"
        >
          <span className="text-slate-200">{props.headerLabel}</span>
          <div
            className={
              isExpanded
                ? "-rotate-90 text-lightSecondaryAccent"
                : "rotate-90 text-lightSecondaryAccent"
            }
          >
            {chevronRight}
          </div>
        </button>
      </h3>
      <div
        id="sect1"
        role="region"
        aria-labelledby="accordion1"
        className={
          !isExpanded
            ? "hidden"
            : "block px-4 bg-darkPrimaryAccent rounded-md mt-2 animate-fade150"
        }
      >
        <span className="leading-8">
          <ul className="list-disc pl-4 md:text-sm">
            {props.items.map((item, index) => {
              return (
                <li key={index} className="my-3">
                  {item}
                </li>
              );
            })}
          </ul>
        </span>
      </div>
    </>
  );
};

export default Accordion;
