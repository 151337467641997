import { SectionContainer } from "./SectionContainer";
import svgToDisplay from "../../assets/illustration-developer.svg";
import { Heading1, Heading2 } from "../typography/Headings";
import { GridContainer } from "../grid/GridContainer";

const About = () => {
  return (
    <SectionContainer id="about">
      <GridContainer>
        {/* First Grid Item */}
        <div>
          <Heading1 className="text-sm text-lightSecondaryAccent md:text-base">
            About me
          </Heading1>
          <Heading2 className="mt-1 text-slate-200 text-3xl font-bold leading-relaxed tracking-wide md:text-4xl">
            Get to know me 🎸
          </Heading2>
          <section className="flex flex-col mt-6 text-slate-300 text-sm font-medium md:text-base md:mt-6">
            <span className="leading-8">
              I consider myself to be a logical, analytical and hard working
              individual who always strives to put my best foot forward no
              matter the circumstances.
            </span>
            <span className="mt-4 leading-8">
              I'm a friendly and joyful person who enjoys engaging with people
              from all walks of life, especially if they have a similar sense of
              humor as I do.
            </span>
            <span className="mt-4 leading-8">
              Outside of playing computer games & electric guitar, working out
              and drinking good coffee, my passion lies with software
              development. The bug bit me while I was completing my engineering
              qualification and, I have been hooked ever since!
            </span>
          </section>
        </div>
        {/* Second Grid Item */}
        <div className="flex items-center justify-center">
          <img
            src={svgToDisplay}
            alt="Developer illustration"
            className="w-80 h-80 md:w-3/4 md:h-3/4"
          />
        </div>
      </GridContainer>
    </SectionContainer>
  );
};

export default About;
