import { SectionContainer } from "./SectionContainer";
import svgToDisplay from "../../assets/illustration-ampersand.svg";
import { gitHub } from "../../helpers/svg-helper";
import linkedInIcon from "../../assets/linkedin-icon.svg";
import { Heading1, Heading2 } from "../typography/Headings";
import { GridContainer } from "../grid/GridContainer";

const Contact = () => {
  return (
    <SectionContainer id="contact">
      <GridContainer>
        {/* First Grid Item */}
        <div>
          <Heading1 className="text-sm text-lightSecondaryAccent md:text-base">
            Contact
          </Heading1>
          <Heading2 className="mt-1 text-slate-200 text-3xl font-bold leading-relaxed tracking-wide md:text-4xl">
            Let's chat! ☕️
          </Heading2>
          <section className="flex flex-col mt-6 text-slate-300 text-sm font-medium md:text-base md:mt-6">
            <span className="mt-4 leading-4">
              Feel free to check out my socials.
            </span>
            <span className="mt-8 leading-4">
              <a
                href="https://www.linkedin.com/in/dieter-l%C3%B6tz-00ab93234/"
                className="flex gap-3 items-center justify-jusify"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={linkedInIcon}
                  alt="LinkedIn Icon"
                  className="w-6 h-6 text-slate-300"
                />
                <span className="text-lightSecondaryAccent">LinkedIn</span>
              </a>
            </span>
            <span className="mt-4 leading-4">
              <a
                href="https://github.com/DieterLotz"
                className="flex gap-3 items-center justify-jusify"
                target="_blank"
                rel="noreferrer"
              >
                {gitHub}
                <span className="text-lightSecondaryAccent">GitHub</span>
              </a>
            </span>
          </section>
        </div>
        <div className="flex items-center justify-center">
          <img
            src={svgToDisplay}
            alt="Contact illustration"
            className="w-60 h-60 md:w-3/4 md:h-3/4"
          />
        </div>
      </GridContainer>
    </SectionContainer>
  );
};

export default Contact;
